import { computed, inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getEmployeeById,
  getUserGroupById,
  selectRouterParam,
} from '@fc-store/selectors';
import { ChatWsService } from '../services/chat-ws.service';
import { ChatStore } from './chat.store';
import {
  ChatBaseMessage,
  ChatMessage,
  PostMessage,
} from '../models/chat-message';
import { Employee } from '@fc-core/models';
import { MessageType } from '../models/message-type';
import { ReactionEvent, ViewedEvent } from '../models/chat-events';
import { TokenData } from '../../auth/models/token-data';
import moment from 'moment-timezone';
import { Emoji } from '@fc-shared/ui/status-set-dialog/status-set-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ChatFacadeService {
  tokenData: TokenData = JSON.parse(
    atob(localStorage.getItem('token').split('.')[1]),
  );
  chatWs = inject(ChatWsService);
  chatStore = inject(ChatStore);
  globalStore = inject(Store);
  groupId = this.globalStore.selectSignal(selectRouterParam('groupId'));
  employeeId = this.globalStore.selectSignal(selectRouterParam('employeeId'));
  messages = this.chatStore.selectors.messages;
  online = this.chatStore.selectors.online;

  constructor() {
    this.chatWs.chatStream.asObservable().subscribe((message: ChatMessage) => {
      if (message) {
        this.processMessage(message);
      }
    });
  }

  getEmployeeById = (id: string) =>
    this.globalStore.selectSignal(getEmployeeById(id));

  getUserGroupById = (id: number) =>
    this.globalStore.selectSignal(getUserGroupById(id));

  currentEmployeeChat = computed(() =>
    this.getEmployeeById(this.employeeId())(),
  );

  currentGroupChat = computed(() => this.getUserGroupById(+this.groupId())());

  get currentGroupOnline(): Signal<Employee[]> {
    return computed(() => {
      const group = this.currentGroupChat();
      const online = this.online();
      if (!group) return [];
      const onlineInds = online.filter((employeeId) =>
        group.employees.includes(employeeId),
      );
      return onlineInds.map((id) => this.getEmployeeById(id)());
    });
  }

  get currentGroupOffline(): Signal<Employee[]> {
    return computed(() => {
      const group = this.currentGroupChat();
      const online = this.online();
      if (!group) return [];
      const offlineInds = group.employees.filter(
        (employeeId) => !online.includes(employeeId),
      );
      return offlineInds.map((id) => this.getEmployeeById(id)());
    });
  }

  getCurrentChannel = computed(() => {
    const group = this.currentGroupChat();
    const employee = this.currentEmployeeChat();
    if (!group) return `pm:${employee?.id}`;
    return `eg:${group.id}`;
  });

  get currentChannelMessages(): Signal<ChatMessage[]> {
    return computed(() => {
      const channel = this.getCurrentChannel();
      const messages = this.chatStore.selectors.messages()[channel] || [];
      return Array.from(messages.values());
    });
  }

  sendMessage(message: PostMessage): void {
    this.chatWs.sendMessage(message);
  }

  sendReaction(message: ChatMessage, emoji: Emoji): void {
    const reaction: ReactionEvent = {
      type: MessageType.REACTION,
      channel: this.getCurrentChannel(),
      message_id: message.id,
      ts: moment().toISOString(),
      emoji: emoji.native,
    };
    this.chatWs.sendReaction(reaction);
  }

  sendViewed(message: ChatMessage): void {
    const viewed: ViewedEvent = {
      type: MessageType.VIEWED,
      channel: this.getCurrentChannel(),
      messageId: message.id,
      ts: moment().toISOString(),
      by: this.currentEmployeeChat()?.id || this.currentGroupChat()?.id,
      content: {},
    };
    this.chatWs.sendViewed(viewed);
  }

  private async processMessage(message: ChatBaseMessage) {
    if (message.type === MessageType.MESSAGE) {
      if (message.from !== this.tokenData.employee_id) {
        const audio = new Audio('assets/sounds/notifications.mp3');
        await audio.play();
      }

      this.chatStore.upsertMessage(message);
    } else if (message.type === MessageType.ONLINE) {
      this.chatStore.setOnline(message.employees);
    }
  }
}
